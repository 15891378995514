<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="货物列表"
      sub-title=""
      @back="() => $router.go(-1)"
    >
      <a-row type="flex">
        <a-statistic title="" :value="'货物名：' + this.$route.query.record.name" />
        <a-statistic
          title=""
          :value="'编号：' + this.$route.query.record.code"
          :style="{
            margin: '0 32px',
          }"
        />
        <a-statistic title="" :value="'HS编码：' + this.$route.query.record.hs_code" />
      </a-row>
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="批次">
              <a-input v-model="queryParam.batch" placeholder="请输入批次" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="是否全部入库">
              <a-switch v-model="queryParam.is_complete"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_count_render" slot-scope="text" style="text-align: right; min-width: 80px; max-width: 90px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handlepurchase(record)" >采购</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateBatchForm'
import { bonded_commodity_create, bonded_commodity_delete, bonded_commodity_list } from '@/api/bonded_commodity'
import { bonded_goods_list } from '@/api/bonded_goods'
let Base64 = require('js-base64').Base64
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      previewurl: '',
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'batch'
        },
        {
          title: '申报价格',
          align: 'center',
          dataIndex: 'customs_price',
          scopedSlots: { customRender: 'price_count_render' }
        },
        {
          title: '币种',
          align: 'center',
          dataIndex: 'currency'
        },
        {
          title: '单位',
          dataIndex: 'unit'
        },
        {
          title: '总数量',
          dataIndex: 'total_count',
          align: 'center',
          scopedSlots: { customRender: 'price_count_render' }
        },
        {
          title: '实际入库数量',
          align: 'center',
          dataIndex: 'inbound_count',
          scopedSlots: { customRender: 'price_count_render' }
        },
        {
          title: '展示数量',
          dataIndex: 'outside_count',
          align: 'center',
          scopedSlots: { customRender: 'price_count_render' }
        },
        {
          title: '全部入库',
          dataIndex: 'is_complete',
          customRender: (text) => text && '是' || '否'
        },
        {
          title: '保质期开始时间',
          dataIndex: 'self_life_begin',
          customRender: (text, record) => record.is_complete ? text : '-'
        },
        {
          title: '保质期结束时间',
          dataIndex: 'self_life_end',
          customRender: (text, record) => record.is_complete ? text : '-'
        },
        {
          title: '账册ID',
          dataIndex: 'book_id'
        },
        {
          title: '条形码',
          dataIndex: 'barcode'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        if (this.queryParam.is_complete === false) {
          this.queryParam.is_complete = undefined
        }
        return bonded_commodity_list(Object.assign(parameter, this.queryParam, { bonded_goods_id: this.$route.params.id }))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      }
    }
  },
  created () {
    this.$route.query.record = JSON.parse(Base64.decode(this.$route.query.record))
  },
  methods: {
    handleAdd () {
      bonded_goods_list({ id: this.$route.params.id }).then(({ data }) => {
        console.log(data)
        this.mdl = data.entries[0] || {}
        console.log(this.mdl)
        this.visible = true
      })
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handlefujian (record) {
      this.current_bill_id = record.id
    },
    handleDelet (record) {
      bonded_commodity_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handlepurchase (record) {
      this.$router.push({ path: '/bonded_goods/purchase/commodity/' + record.id + '/goods/' + this.$route.params.id })
    },
    handleOk (e) {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.self_life_begin = moment(values.self_life_begin).format('YYYY-MM-DD')
          values.self_life_end = moment(values.self_life_end).format('YYYY-MM-DD')
          values.bonded_goods_id = this.$route.params.id
          values.url = values.url && (e.actionUrl + '/' + values.url) || ''
          bonded_commodity_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
          .catch((err) => {
            console.log(err)
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
