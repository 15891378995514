<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="批次">
              <a-input v-decorator="['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="申报价格" >
              <a-input v-decorator="['customs_price', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="币种" >
              <a-select
                v-decorator="[
                  'currency',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="(val,key) in this.$Dictionaries.Currency" :key="key" :value="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数量" >
              <a-input v-decorator="['total_count', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="条形码" >
              <a-input v-decorator="['barcode', {rules: [{required: false, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="账册ID" >
              <a-input v-decorator="['book_id', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="保质期开关" >
              <a-switch
                @change="handleLifeChange"
                v-decorator="['open_self_life', {valuePropName: 'checked'}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="开始时间" extra="格式: YYYY-mm-dd" v-if="is_show">
              <a-date-picker
                v-decorator="['self_life_begin', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledStartDate"
                format="YYYY-MM-DD"
                placeholder="开始时间"
                :open="startOpen"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
            <a-form-item label="结束日期自动计算" v-if="is_show">
              <a-switch
                @change="handleEndChange"
                v-decorator="['auto_calculate', {initialValue: true, valuePropName: 'checked'}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="结束时间" extra="格式: YYYY-mm-dd" v-if="!is_end && is_show">
              <a-date-picker
                v-decorator="['self_life_end', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledEndDate"
                format="YYYY-MM-DD"
                placeholder="结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
            <a-form-item label="备注" >
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:255, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 3, maxRows: 6 }"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'number', 'corporation_id', 'open_self_life', 'batch']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      endOpen: false,
      startOpen: false,
      is_show: false,
      is_end: true,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log(this.model, '1111')
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      console.log(this.model, '1111')
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.handleLifeChange(this.model.open_self_life)
    })
  },
  methods: {
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('self_life_end')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('self_life_begin')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      console.log(open)
      this.startOpen = open
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    handleLifeChange (value) {
      console.log(`selected ${value}`)
      this.is_show = !!value
    },
    handleEndChange (value) {
      console.log(`selected ${value}`)
      this.is_end = !!value
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
